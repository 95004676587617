<template>
  <v-dialog
    v-model="modal"
    content-class="modal-window-container"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        depressed
        outlined
        color="primary"
        data-cy="billing-edit"
        v-on="on"
      >
        <span v-t="`billing.edit`" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span
          class="headline"
          data-cy="billing-modal"
        >
          <span v-t="`billing.${camelize(title)}`" />
        </span>
        <v-spacer />
        <v-icon
          data-cy="billing-modal-close"
          @click="closeModal()"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <material-billing-details-form
          :client-id="clientId"
          :on-submit="submitForm"
          :reset-form="!modal"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    clientId: {
      type: [String, Number],
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    ...mapState('billingDetails', ['loading', 'errors'])
  },
  watch: {
    loading (val, oldVal) {
      if (!val && val !== oldVal && isEmpty(this.errors)) {
        this.closeModal()
      }
    }
  },
  methods: {
    ...mapMutations('billingDetails', ['setErrors']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    submitForm (data) {
      const requestPayload = {
        ...data,
        clientId: this.$route.params.clientId
      }

      this.onSubmit(requestPayload)
    },
    closeModal () {
      this.modal = false
      this.setErrors({})
    }
  }
}
</script>
